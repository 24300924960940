import React, { useEffect, useState } from "react";
import CardMedia from "@mui/material/CardMedia";
import { Box } from "@mui/material";
import r from "../images/rzd44bzx.png";

const Pln = () => {
  let [animate, setAnimate] = useState(false);
  useEffect(() => {
    window.onscroll = function () {
      if (window.scrollY > 1000) {
        setAnimate(true);
      } else {
        setAnimate(false);
      }
    };
  });
  return (
    <Box
      sx={{
        display: "flex",
        gap: "10px",
        transition: "1s",
        left: animate ? "0px" : "-100%",
        alignItems: "center",
        position: "absolute",

        bottom: "0px",
        zIndex: "100",
        backgroundColor: "#f2f2f2",
      }}
    >
      <p style={{ padding: "0px 0px 0px 20px"  , width : "90px"}} >Now Available on</p>
      <Box
        className="pln"
        sx={{ display: "flex", alignItems: "center", gap: "5px" }}
      >
        <CardMedia
          sx={{
            maxWidth: { xs: "100px", md: "150px" },
          }}
          component="img"
          image="https://static.wixstatic.com/media/b54bb7_2b1e66e3fad445e2a3cc7cf3b6d63b6f~mv2.png/v1/fill/w_169,h_48,al_c,lg_1,q_85,enc_auto/google-play-badge-2.png"
        />
        <CardMedia
          sx={{
            maxWidth: { xs: "100px", md: "150px" },
          }}
          component="img"
          image="https://static.wixstatic.com/media/b54bb7_2fb11354a9a248a199f69226c238833a~mv2.png/v1/fill/w_169,h_48,al_c,lg_1,q_85,enc_auto/app-store-2_edited.png"
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        
        }}
        className= "none"
      >
        <CardMedia
          sx={{
            float: "right",
            width: { xs: "40px", md: "70px" },
            position: "absolute",
            right: "15px",
            zIndex: "10",
          }}
          component="img"
          image="https://static.wixstatic.com/media/bdd318_3f1bb792ca8442f2ab619b8917fef9bb~mv2.png/v1/fill/w_63,h_43,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/offline_edited.png"
        />
        <CardMedia
          sx={{
            width: { xs: "60%", md: "100%" },
            height: "100%",
          }}
          component="img"
          image={r}
        />
      </Box>
    </Box>
  );
};

export default Pln;
