import { Box, Grid, Typography, CardMedia } from "@mui/material";
import React from "react";
import "../css/shares.css";
import "../css/style.css";
import SearchIcon from "@mui/icons-material/Search";
import Google from "../components/Google";
import Accor from "../components/Accor";

const Calling = () => {
  let acc = [
    {
      summary:
        "What is International Direct Dialing (IDD) and how does it work with Mobezero International Calling?",
      text: "​International Direct Dialing (IDD) is a feature that allows you to make international calls directly from your phone without the need for an operator. With Mobezero International Calling, you can easily use IDD to connect with your friends, family, and any contact across the world at competitive rates.",
    },
    {
      summary:
        "Does Mobezero International Calling have an app for international calling?",
      text: "Yes, Mobezero mobile app has International Calling that allows you to make international calls easily and conveniently. The app provides crystal-clear voice quality and competitive rates to make your international calls affordable.",
    },
    {
      summary: "Does Mobezero charge any extra fees?",
      text: "There is a fixed per-minute rate that you see before making your call to mobile and landline phones. There are no hidden charges or fees",
    },
    {
      summary:
        "Does Mobezero International Calling offer international calling credits?",
      text: "Yes, from Mobezero you can add International Calling credits that are tailored to your specific needs. Mobezero offers competitive rates and crystal-clear voice quality.",
    },
    {
      summary:
        "Can I use an online calling card with Mobezero International Calling?",
      text: "No, Mobezero International Calling does not currently support the use of online calling cards. However, you can use our mobile app to make international calls easily and affordably.",
    },
    {
      summary:
        "What are international phone cards and can I use them with Mobezero International Calling?",
      text: "International phone cards are pre-paid cards that allow you to make international calls. Mobezero International Calling does not support the use of physical phone cards, but you can use our app to make international calls at competitive rates.",
    },
    {
      summary:
        "How do I make an international call with Mobezero International Calling?",
      text: "To make an international call with Mobezero International Calling, simply install our mobile app, enter the number you wish to call, and press the call button. You can discover our International calling rates on Mobezero App. Our app will connect you with your international contact with crystal-clear voice quality.",
    },
    {
      summary:
        "Can I make online international calls with Mobezero International Calling?",
      text: "Yes, you can make online international calls with Mobezero International Calling. Our mobile app allows you to make international calls easily and conveniently from anywhere in the world.",
    },
    {
      summary:
        "What are the advantages of using Mobezero International Calling for international calls?",
      text: "Mobezero International Calling offers crystal-clear voice quality and competitive rates, making it a cost-effective and convenient way to stay connected with friends, family, and professional contacts across the world.",
    },
    {
      summary: "How do I sign up for Mobezero International Calling?",
      text: "To sign up for Mobezero International Calling, simply install our mobile app and follow the instructions to create an account. Once you have signed up, you can start making international calls right away.",
    },
  ];
  let data = [
    {
      imgOne:
        "https://static.wixstatic.com/media/bdd318_b4810cf851e743c7bb96459ccf841399~mv2.jpeg/v1/fill/w_86,h_83,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-05-14%20at%2021_23_04.jpeg",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "One of my best discoveries from the play store, I wanted to find a quality calling app where I can continue a conversation wits ease, This app gives exactly what I wanted, not only it gives an excellent call quality but also reasonable calls charges",
      h4: "Wise Issam",
    },
    {
      imgOne:
        "https://static.wixstatic.com/media/bdd318_88710acbbefa458db2442ed2ab87b278~mv2.jpg/v1/fill/w_86,h_83,fp_0.23_0.53,q_80,usm_0.66_1.00_0.01,enc_auto/4e205aec8787dd4a772e3dcbb2b7c090_edited.jpg",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "This is a very useful app to call international numbers at very reasonable pricing. The connectivity is good. Even if you have any issues, the support team is very active and helpful in solving the issues.completely recommend this app",
      h4: "Dhinesh Ramalingam",
    },
    {
      imgOne:
        "https://static.wixstatic.com/media/bdd318_7d0541db8e7f4739ab7588b50e15e8cf~mv2.jpg/v1/fill/w_86,h_83,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/istockphoto-524259479-612x612.jpg",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "This is the best app for international calls for me. I am getting a lot of benefits from using it. Can talk at affordable rates, network is very good, interface is very nice, background calling facility, call recording facility and many more",
      h4: "Kuma Kumaran",
    },
  ];
  let Boxes = [
    {
      img: "https://static.wixstatic.com/media/b54bb7_cfbfe6be69304a6eae75c5fe91ed90bf~mv2.png/v1/fill/w_55,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/voice%20(1).png",
      one: "Crystal-Clear Voice Quality",
      two: "With Mobezero International Calling, enjoy crystal-clear voice quality for all your international calls, making sure that every conversation is crisp, clear, and uninterrupted.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_d713d8d785444dea9f92ea5933aefbeb~mv2.png/v1/fill/w_55,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/discount%20(1).png",
      one: "Competitive Rates",
      two: "Mobezero offers competitive rates for international calling, ensuring that you can stay connected with your loved ones and any other contacts across the world without worrying about expensive calling costs.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_6c257abf91794c7eaa99f27292adf6e3~mv2.png/v1/fill/w_55,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/easy-to-use%20(1).png",
      one: "Easy to Use",
      two: "Mobezero is the best international calling app for easy and convenient global connectivity on any device.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_3c5838f81fa2446dad8675b3c15309a8~mv2.png/v1/fill/w_55,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/url%20(1).png",
      one: "Reliable Connectivity",
      two: "Mobezero ensures reliable connectivity with a strong network infrastructure that ensures your international calls are never dropped and you can stay connected for as long as you need.",
    },
  ];
  let BoxesTwo = [
    {
      one: "Multi-Platform Compatibility:",
      two: "Multi-Platform Compatibility: Mobezero International Calling is compatible with all major platforms, including Android, iOS and Windows, ensuring that you can use our service on any device you prefer.",
    },
    {
      one: "Easy Account Management:",
      two: "With Mobezero International Calling, you can easily manage your account online, track your usage, and recharge your balance, making it simple and convenient to stay connected with your global network.",
    },
    {
      one: "HD Voice Quality: ",
      two: "Mobezero International Calling uses advanced technology to deliver HD voice quality for all international calls, ensuring that every conversation is crystal-clear and free of any distortions or disruptions.",
    },
    {
      one: "Advanced Security Features: ",
      two: "Mobezero International Calling uses advanced security features to protect your calls and ensure that your conversations remain private and secure, giving you peace of mind while you stay connected with your loved ones and any other contacts across the world.",
    },
  ];
  let images = [
    "https://static.wixstatic.com/media/b54bb7_94d2c0648891431595438e037dcc9995~mv2.png/v1/fill/w_205,h_387,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-04-25%20at%2010_50_23_iphone12black_portrait.png",
    "https://static.wixstatic.com/media/b54bb7_1373291e7907492d9c8709e8fb45135a~mv2.png/v1/fill/w_205,h_387,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-04-25%20at%2010_49_19%20(3)_iphone12black_portrait.png",
    "https://static.wixstatic.com/media/b54bb7_f4a5f9399fea488f9854440bada83c22~mv2.png/v1/fill/w_205,h_387,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-04-25%20at%2010_49_19%20(2)_iphone12black_portrait.png",
    "https://static.wixstatic.com/media/b54bb7_ca1f503c6f224896a98c9d18270dda8c~mv2.png/v1/fill/w_205,h_387,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202023-04-25%20at%2010_49_19%20(4)_iphone12black_portrait.png",
  ];

  return (
    <Box className="home-parent calling">
      <title>International Calling</title>
      <Box
        sx={{
          position: "relative",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginTop: "60px",
            width: "93vw",
            margin: "auto",
            display: "flex",
          }}
          className="home"
        >
          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt=".."
            className="left"
          />
          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt="..."
            className="right"
          />
          <Box
            className="parent"
            sx={{
              width: "600px",
              padding: { md: "50px 35px", xs: " 30px 20px" },
              backgroundColor: "rgba(255, 255, 255, 0.568)",
              transform: { lg: "translateX(200px)" },
              borderRadius: "20px",
              textAlign: "left",
            }}
          >
            <Typography variant="h1" color="inherit">
              <Typography variant="span" color="inherit">
                Mobezero
              </Typography>
              International Calling
            </Typography>
            <form action="" style={{ position: "relative" }}>
              <SearchIcon
                sx={{
                  position: "absolute",
                  left: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  fontSize: "20px",
                  color: "greenyellow",
                }}
              />
              <input type="text" placeholder="what would you like to call" />
            </form>
          </Box>
        </Box>
      </Box>
      <Box className="second" sx={{ width: "90vw", margin: "auto" }}>
        <Typography
          variant="h1"
          color="inherit"
          sx={{
            fontSize: "30px",
            marginBottom: "100px",
            marginTop: "60px",
            textAlign: "center",
            fontWeight: "500",
            letterSpacing:"1px"
          }}
        >
          <Typography
            variant="span"
            color="inherit"
            sx={{ display: "block", marginBottom: "10px" }}
          >
            {" "}
            Discover the Benefits of Mobezero
          </Typography>{" "}
          International Calling
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "50px",
            flexDirection: "row",
          }}
        >
          {Boxes.map((item) => {
            return (
              <Grid
                item
                xs={11}
                md={4}
                sx={{
                  boxShadow: "1px 1px 2px lightgray",
                  marginBottom: "30px",
                  backgroundColor: "white",
                  padding: "30px",
                  borderRadius: "30px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <img
                  src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                  alt=".."
                  style={{
                    position: "absolute",
                    top: "-108px",
                    zIndex: "-1000",
                    transform: " translateX(90px) rotate(270deg)",
                    width: "35px",
                  }}
                />
                <img
                  style={{
                    width: "35px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={item.img}
                  alt="..."
                />
                <Typography
                  sx={{ fontSize: "15px", textAlign: "center", margin: "10px" }}
                  variant="p"
                  color="inherit"
                >
                  {item.one}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{
                    textAlign: "center",
                    color: "#757575",
                    fontSize: "16px",
                    lineHeight:"22px"
                  }}
                >
                  {item.two}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          // marginBottom: "50px",
          marginTop: "60px",
          textAlign: "center",
          letterSpacing:"1px",
          fontWeight: "500",
        }}
      >
        <Typography
          variant="span"
          color="inherit"
          sx={{ display: "block", marginBottom: "10px" }}
        >
          {" "}
          Discover Unique International
        </Typography>{" "}
        Calling Features{" "}
      </Typography>
      <Box
        className="third"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          margin: "auto",
          width: "95vw",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "50px",
            flexDirection: "row",
            width: "90vw",
            margin: "auto",
          }}
        >
          {BoxesTwo.map((item) => {
            return (
              <Grid
                item
                xs={11}
                md={4}
                sx={{
                  boxShadow: "1px 1px 2px lightgray",
                  marginBottom: "30px",
                  backgroundColor: "white",
                  padding: "50px 30px",
                  borderRadius: "30px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <CardMedia
                  sx={{
                    width: "40px",
                    borderRadius: "0px 0px 0px 0px",
                    transform: "translateX(200%) rotate(90deg)",
                    position: "absolute",
                    top: "-85px",
                  }}
                  component="img"
                  image="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                />
                <Typography variant="p" color="inherit" sx={{marginTop:"10px"}}>
                  {item.one}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{ color: "#757575", marginTop:"10px", fontSize:"16px", lineHeight:"22px" }}
                >
                  {item.two}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          marginBottom: "100px",
          marginTop: "60px",
          textAlign: "center",
          letterSpacing:"1px",
          fontWeight: "500",
        }}
      >
        How Does it Work?
      </Typography>
      <Box
        sx={{
          width: { xs: "83vw", md: "80vw" },

          backgroundColor: "white",
          borderRadius: "20px",
          margin: "auto",
          padding: { xs: "10px", sm: "50px" },
          position: "relative",
        }}
      >
        <img
          className="img"
          style={{
            position: "absolute",
            top: "-102.5px",
            display:{xs:"none",md:"block"},
            left:"180px"
          }}
          src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_48,h_253,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt="..."
        />

        <Box
          className="images"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          {images.map((item) => {
            return (
              <Box>
                <img style={{ width: "200px" }} src={item} alt="..." />
              </Box>
            );
          })}
        </Box>
        <Box sx={{ padding: { xs: "40px", sm: "80px" } }}>
          <Typography variant="p" color="inherit" sx={{ color: "#757575" , fontSize:"18px", lineHeight:"23px" }}>
            Mobezero app offers international calling, allowing you to make
            low-cost calls to destinations around the world. Here are the steps
            to use Mobezero international calling:
          </Typography>

          <ol
            style={{
              color: "#757575",
              margin: { xs: "0px", sm: "20px" },
              marginTop: "20px",
              fontSize: "18px",
              display:"flex",

              flexDirection:"column",
              gap:"10px"
            }}
          >
            <li style={{lineHeight:"23px"}}>Install Mobezero app on your device.</li>

            <li style={{lineHeight:"23px"}}>Open the Mobezero app and select the "Call Rates" Option.</li>

            <li style={{lineHeight:"23px"}}> Choose the country you want to call.</li>

            <li style={{lineHeight:"23px"}}>
              Check the call rate for the destination country. You may need to
              add credit to your Mobezero account if you haven't done so
              already.
            </li>

            <li style={{lineHeight:"23px"}}>
              Once you have sufficient credit in your account, you can start
              making calls. The call will be routed through Mobezero's network,
              which provides low-cost rates for international calls.
            </li>
          </ol>
          <Typography variant="p" color="inherit" sx={{ color: "#757575" , fontSize:"18px",  lineHeight:"23px" }}>
            Also you can make one free call up to 0.10 USD before you recharge.
          </Typography>
        </Box>
      </Box>
      <Google data={data} />

      <Accor acc={acc} />
    </Box>
  );
};

export default Calling;
