import { Box, Typography, CardMedia } from "@mui/material";
import React from "react";
import "../css/shares.css";
import "../css/style.css";
import SearchIcon from "@mui/icons-material/Search";
import Accor from "../components/Accor";
const ESIM = () => {
  let acc = [
    {
      summary: "What is Mobezero eSIM? ",
      text: "Mobezero eSIM is a virtual SIM card that allows you to use your mobile phone or device without a physical SIM card. It provides a simple and convenient way for travelers to stay connected while on the go.",
    },
    {
      summary: "What are the benefits of using Mobezero eSIM? ",
      text: "Mobezero eSIM offers several benefits to travelers, including cost savings, greater flexibility, and the ability to use multiple phone numbers on a single device.",
    },
    {
      summary: "How do I activate my Mobezero virtual SIM? ",
      text: "To activate your Mobezero Virtual SIM card, you will need to purchase a plan and download the virtual sim onto your phone or device. Mobezero offers detailed instructions and support to guide you through the activation process.",
    },
    {
      summary: "Can I use Mobezero eSIM on any phone or device? ",
      text: "Mobezero eSIM is compatible with a wide range of devices, including the latest smartphones and tablets from Apple, Samsung, Google, and other manufacturers. If you want to check the device compatibility, you can do the compatibility test here..",
    },
    {
      summary: "Can I use Mobezero eSIM in any country?",
      text: "Mobezero eSIM is available in over 150 countries around the world, with coverage in most major cities and tourist destinations.",
    },
    {
      summary: "How much does Mobezero eSIM cost? ",
      text: "Mobezero offers a range of plans to suit different travel needs and budgets. You can see the plans by searching for your destination..",
    },
    {
      summary: "How do I manage my Mobezero eSIM account",
      text: "Mobezero offers a user-friendly app where you can manage your account, view your usage, and purchase additional data or services as needed.",
    },
    {
      summary:
        "What happens if I run out of data or credit on my Mobezero eSIM? ",
      text: "Mobezero offers easy options to ensure that you always have data and credit when you need it most. You can purchase additional data or credit through the Mobezero website.",
    },
    {
      summary: "Is Mobezero e SIM secure and reliable?",
      text: "At Mobezero, we prioritize the safety and security of your data and communications. We have implemented state-of-the-art security measures and robust network infrastructure to ensure your information is always protected. In addition, our dedicated customer support team is available round-the-clock to address any queries or problems that you may encounter while on the go.",
    },
    {
      summary: "How do I use Mobezero travel eSIM?",
      text: "You can purchase a plan that suits your data needs on the Mobezero website, and download the eSIM profile onto your phone. Once activated, you can start using data in the country you're traveling to.",
    },
    {
      summary: "What countries does Mobezero travel eSIM work in?",
      text: "Mobezero travel eSIM works in over 150 countries around the world. You can check the coverage map on the Mobezero website to see if your destination is covered.",
    },
    {
      summary: "What is the duration of Mobezero travel eSIM plans in the USA?",
      text: "Mobezero travel eSIM plans vary in duration, with options available for 7 to 30 days.",
    },
  ];

  let Boxes = [
    {
      img: "https://static.wixstatic.com/media/b54bb7_9998842dc0c3481ba61c6caee4f9793f~mv2.png/v1/fill/w_84,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/world.png",
      one: "Explore the world while maintaining seamless connectivity without incurring costly roaming charges",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_e8ecc251489b4016b0dbd5afaa1ca230~mv2.png/v1/fill/w_84,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/phone-sim.png",
      one: "Instantly connected as soon as your flight touches down. No need to search for a local SIM card",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_aaf15a76e8e24ebaaf447e767c797fc6~mv2.png/v1/fill/w_73,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/speedometer.png",
      one: "Superior Connectivity Speed and Reliability throughout your entire journey",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_edf5755abcdb4899b7ab311062d9cb81~mv2.png/v1/fill/w_84,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/love-message.png",
      one: "Full visibility and control over your data usage with Mobezero App",
    },
  ];

  let images = [
    "https://static.wixstatic.com/media/b54bb7_d587c3230a9f421a89c6a16652acc12c~mv2.png/v1/fill/w_194,h_404,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%2027.png",
    "https://static.wixstatic.com/media/b54bb7_42d941789c6d4e96aee2734f82780d45~mv2.png/v1/fill/w_192,h_404,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%2028.png",
    "https://static.wixstatic.com/media/b54bb7_b95d01b9de2941e0b552e6ef782298f1~mv2.png/v1/fill/w_193,h_404,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%2029.png",
    "https://static.wixstatic.com/media/b54bb7_64e70970e2574f4c8fb444ed6e058451~mv2.png/v1/fill/w_193,h_404,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%2028_edited.png",
  ];

  return (
    <Box className="esim-parent" sx={{ margin: "auto" }}>
      <title>eSiM</title>

      <Box
        sx={{ position: "relative", display: "flex", justifyContent: "center" }}
      >
        <img
          src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt=".."
          className="left"
        />
        <img
          src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt="..."
          className="right"
        />
        <Box
          sx={{ width: "93vw", margin: "auto", display: "flex" }}
          className="home"
        >
          {/* <CardMedia className="im" component="img" image={{xs : esim , md :esims}} sx={{borderRadius:"0px 0px 50px 50px",position :"absolute" }}/> */}

          <Box
            className="parent"
            sx={{
              width: "600px",
              padding: { md: "50px 35px", xs: " 30px 20px" },
              backgroundColor: "rgba(255, 255, 255, 0.568)",
              transform: { lg: "translateX(200px)" },
              borderRadius: "20px",
              textAlign: "center",
            }}
          >
            <Typography variant="h1" color="inherit">
              <Typography variant="span" color="inherit">
                Mobezero Travel eSIM
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ fontSize: "25px", color: "#979797" }}
              >
                The Ultimate Travel Companion
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ fontSize: "20px", color: "#9AA9AC" }}
              >
                for Global Adventure
              </Typography>
            </Typography>
            <form action="" style={{ position: "relative" }}>
              <SearchIcon
                sx={{
                  position: "absolute",
                  left: { xs: "55px", md: "115px" },
                  top: "50%",
                  transform: "translateY(-50%)",
                  fontSize: "20px",
                  color: "greenyellow",
                }}
              />
              <input
                style={{ display: "block", margin: "auto" }}
                type="text"
                placeholder="Where do you wanna travel"
              />
            </form>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          marginBottom: "100px",
          margin: "60px  auto",
          letterSpacing:"1px",
          textAlign: "left",
          width: "80vw",
          fontWeight: "500",
        }}
      >
        How Does it Work?
      </Typography>
      <Box
        sx={{
          width: { xs: "83vw", md: "80vw" },


          backgroundColor: "white",
          borderRadius: "20px",
          margin: "auto",
          padding: { xs: "10px", sm: "50px" },
          position: "relative",
        }}
      >
        <img
        className="img"
          style={{
            position: "absolute",
            top: "-103px",
            right: "180px",
            transform: "rotate(90deg)",
            display:{xs:"none",md:"block"},
          }}
          src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_48,h_253,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt="..."
        />

        <Box
          className="images"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          {images.map((item) => {
            return (
              <Box>
                <img style={{ width: "200px" }} src={item} alt="..." />
              </Box>
            );
          })}
        </Box>
        <Box sx={{ padding: { xs: "40px", sm: "80px" } }}>
          <Typography variant="p" color="inherit" sx={{ color: "#757575" , lineHeight:"23px" , fontSize:"18px"}}>
            Mobezero Travel eSIM allows you to activate and manage an eSIM
            profile on your device. Here are the steps to activate an eSIM using
            the Mobezero app
          </Typography>

          <ol
            style={{
              color: "#757575",
              marginTop: "20px",
              transform:"translateX(12px)",
              fontSize: "18px",
               lineHeight:"23px"
            }}
          >
            <li style={{ marginTop: "20px" }}>
              Check Device Compatibility and get the app- Make sure your device
              is eSIM compatible, select your preferred country/ region and a
              data package, and follow the on-screen instructions to complete
              the purchase, providing necessary personal and payment
              information.
            </li>

            <li style={{ marginTop: "20px" }}>
              {" "}
              Get your eSIM- Make sure your device is eSIM compatible, select
              your preferred country/ region and a data package, and follow the
              on-screen instructions to complete the purchase, providing
              necessary personal and payment information.
            </li>

            <li style={{ marginTop: "20px" }}>
              {" "}
              Activate on Your Device- To activate an eSIM on your device, go to
              Settings > Network and Internet > SIM cards, select the "+"icon,
              choose to download a data eSIM, and follow the prompts to complete
              the process.
            </li>
          </ol>
          <Typography
            variant="p"
            color="inherit"
            sx={{ color: "#757575", display: "block", padding: "30px 0px" , fontSize:"18px",lineHeight:"23px"}}
          >
            You can also manage your eSIM profile using the Mobezero app, such
            as checking your data usage, updating your plan, and removing the
            eSIM profile from your device.
          </Typography>
        </Box>
      </Box>

      <Box className="second" sx={{ width: "80%", margin: "auto" }}>
        <Typography
          variant="h1"
          color="inherit"
          sx={{
            fontSize: "30px",
            marginBottom: "100px",
            marginTop: "60px",
            letterSpacing:"1px",
            textAlign: "center",
            fontWeight: "500",
            textIndent: "60px",
          }}
        >
          Unlock the Benefits of Mobezero Travel eSIM
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "80px",
          }}
        >
          {Boxes.map((item) => {
            return (
              <Box
                sx={{
                  width: "320px",
                  padding: "30px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  position: "relative",
                  marginTop: "50px",
                }}
              >
                <img
                  src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                  alt=".."
                  style={{
                    position: "absolute",
                    top: "-75px",
                    left: "95px",
                    width: "35px",
                    transform: "rotate(90deg)",
                  }}
                />
                <img
                  style={{
                    width: "55px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={item.img}
                  alt="..."
                />
                <Typography
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    margin: "10px",
                    color: "#757575",
                    lineHeight:"22px"
                  }}
                  variant="p"
                  color="inherit"
                >
                  {item.one}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Accor acc={acc} />
    </Box>
  );
};

export default ESIM;
