import { Box, Typography } from "@mui/material";
import React from "react";
import "../css/shares.css";
const Privacy = () => {
  return (
    <Box
      sx={{
        margin: "220px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <title>privacy</title>

      <Typography
        variant="h1"
        color="inherit"
        sx={{ fontSize: "30px", textAlign: "center", fontWeight: "bold" , letterSpacing:"2px" }}
      >
        Privacy Policy
      </Typography>
      <hr
        style={{
          width: "45vw",
          margin: " 30px auto",
          border: "1.5px solid black",
        }}
      />
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero built the mobezero app as a Free app with in-app purchases.
        This SERVICE is provided by mobezero at a minimal cost and is intended
        for use as is. This page is used to inform visitors regarding our
        policies regarding the collection, use, and disclosure of Personal
        Information if anyone decided to use our Service. If you choose to use
        our Service, then you agree to the collection and use of information in
        relation to this policy.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        The Personal Information that we collect is used for providing and
        improving the Service. We will not use or share your information with
        anyone except as described in this Privacy Policy. The terms used in
        this Privacy Policy have the same meanings as in our Terms and
        Conditions, which is accessible at Mobezero unless otherwise defined in
        this Privacy Policy.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        Information Collection and Use
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information, including
        but not limited to your telephone number and email address. The
        information that we request will be retained by us and used as described
        in this privacy policy.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        The app does use the following third-party services that may collect
        information used to identify you.
      </Typography>
      <ul
        style={{
          width: "70vw",
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        <li style={{ marginLeft: "20px" }}> Google Play Services</li>
        <li style={{ marginLeft: "20px" }}> Google Analytics for Firebase</li>
        <li style={{ marginLeft: "20px" }}>Firebase Crashlytics</li>
        <li style={{ marginLeft: "20px" }}>Facebook</li>
        <li style={{ marginLeft: "20px" }}> Adjust</li>
      </ul>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We want to inform you that whenever you use our Service, we collect data
        and information on your phone called Log Data. This Log Data may include
        information such as your phone number, mobile operator, device Internet
        Protocol ("IP") address, device name, operating system version, the
        configuration of the app when utilizing our Service, the time and date
        of your use of the Service, and other statistics. Connection-specific
        information such as your phone number, mobile operator, IP address, etc.
        will be accessed for the purpose of user identification and customer
        support and to communicate with you. Call-related data such as phone
        numbers of the calling and receiving party, start time, call duration,
        call quality, etc. will be retained for the purpose of providing better
        customer support and continuous improvements to our Products and
        Services.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        When you install the mobezero App and register, you will be required to
        provide us with your phone number, which is used only for identification
        and account verification purposes. Your phone number becomes your
        mobezero username as well as your caller identification number. At the
        time of registration, we will also check whether you already have a
        mobezero account. mobezero also collects your email for the purpose of
        identification and to send you important updates regarding your account,
        payments, and our Services.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        mobezero reads the contents of the activation SMS sent to you by
        mobezero for the sole purpose of service activation. mobezero will not
        read the contents of any other messages on your device.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        No payment-related information is stored on mobezero. Payment
        information such as your bank details and other financial information
        relevant to your chosen payment method are collected in compliance with
        PCI DSS standards and used for customer billing, fraud prevention,
        refunds, bonuses and delivering prizes.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        With your consent, contacts will be gathered but limited to phone
        numbers from the user’s device which will be transmitted securely to our
        servers and will not be shared with any 3rd party. mobezero will sync
        the contacts on your phone with the contacts list in your mobezero app.
        Other mobezero users in your contact list will be identified, to and
        enable you to transfer app credit to them conveniently. However, if you
        choose not to share your contacts, you will still be able to transfer
        credit to other mobezero users if you wish to.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        The microphone on your device needs to be accessed in order to enable
        outgoing calls and incoming calls for virtual numbers.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We collect, retain and process your personal data in accordance with the
        applicable laws and regulations. Data will be up
      </Typography>
    </Box>
  );
};

export default Privacy;
