import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import Calling from "./pages/Calling";
import Numbers from "./pages/Numbers";
import {
  createBrowserRouter,
  Route,
  RouterProvider,
  createRoutesFromElements,
  createHashRouter,
} from "react-router-dom";
import Home from "./pages/Home";
import ESIM from "./pages/eSIM";
import Privacy from "./pages/privacy";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Security from "./pages/Security";
import NotFound from "./components/NotFound";
const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index element={<Home />} />
      <Route path="eSIM" element={<ESIM />} />
      <Route path="calling" element={<Calling />} />
      <Route path="virtual-numburs" element={<Numbers />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="FAQ" element={<FAQ />} />
      <Route path="contact" element={<Contact />} />
      <Route path="security" element={<Security />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
