import { Box, Grid, Typography, CardMedia } from "@mui/material";
import React from "react";
import { FaStar } from "react-icons/fa";

const Google = (props) => {
  console.log(props.data);

  return (
    <Box sx={{ marginTop: " 50px" }}>
      <Grid
        container
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: {xs:"flex-start",md:"center"},
          gap: "50px",
          width: { xs: "83vw", md: "80vw" },
          padding: "40px",
          borderRadius: "20px",
          backgroundColor: "white",
          margin: "auto",
        }}
      >
        <CardMedia
          component="img"
          image="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          sx={{
            position: "absolute",
            zIndex: "-100",
            bottom: "-155px",
            display: { xs: "none", md: "block" },
            left: "180px",
            width: "50px",
            transform: "rotate(90deg)",
          }}
        />
        {props.data.map((item) => {
          return (
            <Grid item xs={11} sm={12} md={6} lg={3.2} sx={{ textAlign: "center" }}>
              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <Grid item>
                  <CardMedia
                    component="img"
                    sx={{ width: "70px", height: "70px", borderRadius: "100%" }}
                    title="user"
                    image={item.imgOne}
                  />
                </Grid>
                <Grid item sx={{ paddingLeft: "20px" }}>
                  <CardMedia
                    component="img"
                    sx={{ width: "100px" }}
                    title="google"
                    image={item.imgTwo}
                  />
                  <Box sx={{marginTop:"10px" , display:"flex" , gap:"3px"}}>
                    <FaStar  style={{color:"orange"}}/>
                    <FaStar  style={{color:"orange"}}/>
                    <FaStar  style={{color:"orange"}}/>
                    <FaStar  style={{color:"orange"}}/>
                    <FaStar style={{color:"orange"}} />
                  </Box>
                </Grid>
              </Grid>
              <Typography
                variant="p"
                sx={{
                  fontSize: "15px",
                  marginTop: "35px",
                  color: "#757575",
                  textAlign:"left",
                  lineHeight: "20px",
                }}
                color="inherit"
              >
                {item.p}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  fontSize: "18px",
                  textAlign: "center",
                  marginTop: "35px",
                  color: "black",
                  fontWeight: "500",
                }}
                color="inherit"
              >
                {item.h4}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Google;
