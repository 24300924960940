import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

const Security = () => {
  return (
    <Box
      sx={{
        margin: "220px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <title>security</title>

      <Typography
        variant="h1"
        color="inherit"
        sx={{ fontSize: "30px", textAlign: "center", fontWeight: "bold" , letterSpacing:"2px" }}
      >
        Security Information
      </Typography>
      <hr
        style={{
          width: "45vw",
          margin: " 30px auto",
          border: "1.5px solid black",
        }}
      />

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        Servers
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero is running on branded servers with multiple redundancy options.
        Mobezero operates on a private cloud connecting USA(Multiple Locations),
        UK, Ireland, Germany, Singapore, Australia, and Hong Kong.
      </Typography>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        All servers are powered by GIGA bit networks and secured by CISCO. We
        use the highest quality hardware for our servers and all our data runs
        on multiple SAS drives.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        System Security
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We use firewalls and logical access control to protect our servers from
        unauthorized system access, allowing only trusted operations personnel
        to manage our systems. We also make sure to use strong configuration
        standards to harden our servers, and we keep them up-to-date with the
        latest security patches.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        Application security
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We support strong cryptography for communication over public networks,
        so that your Mobezero Dashboard password, API secret, and contents of
        your communications may be protected in transit as set forth below.
      </Typography>
      <Table
        sx={{
          width: { xs: "90vw", md: "70vw" },
          margin: "50px auto",
          border: "1px solid rgba(224, 224, 224, 1)",
        }}
        size="small"
        aria-label="a dense table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ padding: "10px" }}>
              Type of communication
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>
              Secure protocols used
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>Other protocols used</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ padding: "10px" }}>
              Between customers and Mobezero APIs
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>
              HTTPS, SMPP-over-SSL, SIPS, SMPP-over-IPsec
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>SMPP, SIP, RTP</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "10px" }}>Mobezero Dashboard</TableCell>
            <TableCell sx={{ padding: "10px" }}>HTTPS</TableCell>
            <TableCell sx={{ padding: "10px" }}>-</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ padding: "10px" }}>
              Between Mobezero and carriers
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>
              VPN, HTTPS, SMPP-over-IPsec, ENUM-over-IPsec
            </TableCell>
            <TableCell sx={{ padding: "10px" }}>
              HTTP, SMPP, ENUM, SIP, RTP
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        In connection with the provision of our services, Mobezero has secured
        direct relationships with telecommunications carriers and similar
        services providers around the globe. While many of our connections with
        these carriers are secure, some of these "last mile" connections are
        unencrypted. This is beyond our control and depends on the carrier, as
        some telecommunications providers have legacy infrastructure and do not
        currently support secure protocols. We opt for secured communication
        with carriers when available.
      </Typography>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero is committed to supplying a highly available platform and we do
        our best to minimize outages. Through use of a content delivery network,
        geographically redundant data centers, and redundancy within each data
        center, we ensure failovers exist at several levels to maximize uptime.
      </Typography>

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        What are cookies?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        A cookie is a small text file that a website saves on your computer or
        mobile device when you visit that site. Cookies enable the website to
        remember your actions and preferences (such as login, language, font
        size and other display preferences) over a period of time, so you don't
        have to keep re-entering them whenever you come back to the site or
        browse from one page to another.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        How do we use cookies?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We use two types: persistent cookies and session cookies. A persistent
        cookie helps us recognize you as an existing user, so it's easier to
        return to Mobezero.io. After you sign in, a persistent cookie stays in
        your browser and will be read by Mobezero.io when you return to the
        site. Session cookies only last for as long as the session (usually the
        current visit to a website or a browser session).
      </Typography>
    </Box>
  );
};

export default Security;
