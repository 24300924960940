import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const Accor = (props) => {
  return (
    <Box sx={{              width: { xs: "83vw", md: "80vw" },
    margin: "auto" }}>
      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          marginBottom: "30px",
          marginTop: "160px",
          letterSpacing:"1px",
          fontWeight: "500",
        }}
      >
        Frequently Asked Question
      </Typography>
      {props.acc.map((item) => {
        return (
          <Accordion
            sx={{
              padding: "5px",
              borderRadius: "15px 15px 0px 0px",
              boxShadow:
                "1px 1px 2px rgb(225, 47 ,143) , -1px -1px 2px rgb(225, 47 ,143,0.5)",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                "&:hover": {
                  color: "rgb(225, 47 ,143)",
                },
              }}
            >
              {item.summary}
            </AccordionSummary>
            <AccordionDetails sx={{fontSize:"16px", lineHeight:"20px"}}>{item.text}</AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default Accor;
