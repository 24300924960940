import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
const Contact = () => {
  return (
    <Box
      sx={{
        margin: "220px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <title>contact</title>

      <Typography
        variant="h1"
        color="inherit"
        sx={{ fontSize: "30px", textAlign: "center", fontWeight: "bold" , letterSpacing:"2px" }}
      >
        Contact Us
      </Typography>
      <hr
        style={{
          width: "45vw",
          margin: " 30px auto",
          border: "1.5px solid black",
        }}
      />
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Thank you for picking out Mobezero as your communication companion. We
        genuinely care about your needs and we are here to help you every step
        of your journey. Mobezero is entirely committed to providing you with
        the greatest customer support experience possible, whether you have
        questions, need assistance, or simply want to express your thoughts.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        Phone:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        For immediate assistance, please call our dedicated helpline numbers:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto 0px auto",
          lineHeight: "25px",
        }}
      >
        +15164689819
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Our knowledgeable and friendly representatives are available to address
        your inquiries and provide guidance tailored to your specific needs.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        Live Chat:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        For real-time assistance, we offer a convenient Live Chat option through
        the Mobezero App & Mobezero website. Simply access the app and initiate
        a chat session with our support team. We're here to help you navigate
        any challenges you may encounter and ensure a seamless experience.
      </Typography>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        Email:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        You can also reach us via email at sales@mobezero.com ,
        support@mobezero.com or reseller@mobezero.com Our support team monitors
        this inbox closely and strives to respond promptly to ensure your
        questions are answered and issues are resolved efficiently.
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        Street Address:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        6804 NE 79th court
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        City:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        Portland /United States
      </Typography>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        State:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        OR
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "auto",
          lineHeight: "25px",
        }}
      >
        Zip code:
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "0px auto 30px auto ",
          lineHeight: "25px",
        }}
      >
        97218
      </Typography>
      
      
      
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",

          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        For the latest news, updates, and promotions, be sure to follow us on
        social media platforms. Connect with us on Facebook, Twitter, and
        Instagram to stay informed about new features, exciting offers, and
        useful tips for maximizing your communication experience.
      </Typography>
      <Box
        className="icons"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Link>
          <FacebookIcon sx={{ color: "black", padding: "0px" }} />
        </Link>
        <Link>
          <TwitterIcon sx={{ color: "black", padding: "0px" }} />
        </Link>
        <Link>
          <YouTubeIcon sx={{ color: "black", padding: "0px" }} />
        </Link>
      </Box>

      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        We are dedicated to providing you with exceptional support and ensuring
        your satisfaction with our communication solutions. Don't hesitate to
        reach out to us if you need any assistance while you are exploring our
        features. Your convenience is our priority, and we are here to help you
        stay connected and thrive.
      </Typography>
    </Box>
  );
};

export default Contact;
