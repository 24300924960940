import { Box, Typography, CardMedia, Grid } from "@mui/material";
import React from "react";
import "../css/shares.css";
import "../css/style.css";
import Google from "../components/Google";
import { Link } from "react-router-dom";
import person1 from "../images/person 1.png";
import person2 from "../images/person 2.png";
import person3 from "../images/person 3.png";
import google from "../images/google.png";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import home from "../images/home.webp";
const Home = () => {
  let data = [
    {
      imgOne: person1,
      imgTwo: google,

      p: "This is the best app for international calls for me. I am getting a lot of benefits from using it. For example - can talk at affordable rates, network is very good, interface is very nice, background calling facility, call recording facility and many more",
      h4: "Washim Khan",
    },
    {
      imgOne: person2,
      imgTwo: google,

      p: "This is a very useful app to call international numbers at very reasonable pricing. The connectivity is good. Even if you have any issues, the support team is very active and helpful in solving the issues. I would completely recommend this app to friends.",
      h4: "Dinesha Ramalingam",
    },
    {
      imgOne: person3,
      imgTwo: google,

      p: "One of my best discoveries from the play store, I wanted to find a quality calling app where I can continue a conversation wits ease, This app gives exactly what I wanted, not only it gives an excellent call quality but also reasonable calls charges",
      h4: "Vishaka Issam",
    },
  ];
  let Boxes = [
    {
      img: "https://static.wixstatic.com/media/bdd318_8e35af00b9af4e1abbdc1cdd8ae59463~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/plane.png",
      one: "Travel eSIM",
      two: "Stay connected with affordable Data eSIMs in 150+ Countries and regions. Travel freely without Data limitations.",
      link: "Get your eSIM Today",
      to: "eSIM",
    },
    {
      img: "https://static.wixstatic.com/media/bdd318_3e0ce84f7477456a9be1c53f0cd898bd~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/globe%20(1).png",
      one: "Virtual Numbers",
      two: "Get a virtual phone number and Manage business, Stay private, and Connect globally with ease.",
      link: "Get Started Today",
      to: "virtual-numburs",
    },
    {
      img: "https://static.wixstatic.com/media/bdd318_1935a7acfc434ec1a092435cd2f86020~mv2.png/v1/fill/w_48,h_48,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/phone-call%20(6).png",
      one: "International Calling",
      two: "Get connected with friends, family, and any contact across the world. Crystal-clear voice calls at competitive rates.",
      link: "Try Now!",
      to: "calling",
    },
  ];

  let work = [
    {
      h1: "Mobezero Travel eSIM",
      p: "Mobezero Travel eSIM allows you to activate and manage an eSIM profile on your device. Here are the steps to activate an eSIM using the Mobezero app:",
      ol: [
        "Install Mobezero app on your device.",
        "Open the Mobezero app and select  'eSIM'.",
        "Make sure that your device is eSIM compatible",
      ],
      link: "Read More",
      to: "eSIM",
    },
    {
      h1: "Mobezero Virtual Numbers",
      p: "The Mobezero app offers virtual phone numbers that you can use to receive calls and SMS messages. Following these steps you can activate a Mobezero virtual number:​",
      ol: [
        "Install Mobezero app on your device.",
        "Open the Mobezero app and Choose 'Buy Number' option.",
        "Choose your preferred country and area code for the virtual number.",
        "Follow the instructions on the screen to complete the purchase.",
        "You may need to provide personal and payment information.",
      ],
      link: "Read More",
      to: "virtual-numburs",
    },
    {
      h1: "International Calling",
      p: "Mobezero app offers international calling, allowing you to make low-cost calls to destinations around the world. You can make one free call up to 0.1 USD.​",
      ol: [
        "Simply install Mobezero app on your device.",
        "Open the Mobezero app and select 'International Calls' option..",
        "Choose the country you want to call and enter the phone number you want to call. ",
      ],
      link: "Read More",
      to: "calling",
    },
  ];

  return (
    <Box className="h-parent">
      <title>Home</title>
      <Box sx={{ position: "relative" }} className="one">
        <Box
          sx={{
            padding: { xs: "20px" },
            paddingTop: { xs: "40px" },
            width: "93vw",
            marginTop: { xs: "75px", md: "60px" },
            margin: "auto",
            flexDirection: { xs: "column", lg: "row" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
          }}
          className="home"
        >
          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt=".."
            className="left"
            style={{ zIndex: "10" }}
          />
          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt="..."
            className="right"
            style={{ zIndex: "10" }}
          />
          <Box
            className="parent"
            sx={{
              left:{md: "50px"},
              bottom:{md: "100px"},
              transform: { lg: "translateX(50px)" },
              zIndex: "0",
              backgroundColor: "white",
            }}
          >
            <Typography variant="h1" color="inherit">
              <Typography
                variant="span"
                className="span"
                color="inherit"
                sx={{ color: "#EF3575", fontSize: "50px", fontWeight: "bold" , fontFamily:"poppins-semibold, poppins, sans-serif"}}
              >
                Ultimate Travel
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ color: "#757575", fontSize: "30px" }}
              >
                Companion for globetrotters
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ color: "#F57F26", fontSize: "25px" }}
              >
                Stay connected with
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  transform: "translateX(70px)",
                  gap: "8px",
                }}
              >
                <Link to="eSIM">
                  <ArrowForwardIosIcon />
                  Travel eSIM
                </Link>
                <Link to="virtual-numburs">
                  <ArrowForwardIosIcon />
                  Virtual Numbers
                </Link>
                <Link to="calling">
                  <ArrowForwardIosIcon />
                  International Calling
                </Link>
              </Box>
            </Typography>
          </Box>
          {/* <Box className="img">
            <CardMedia
              component="img"
              title=""
              image={home}
              sx={{
                display: "flex",
                justifyContent: "center",
                width: { xs: "300px", md: "auto" },
                transform: "rotate(-90deg)",
              }}
            />
          </Box> */}
        </Box>
      </Box>
      <Box className="second" sx={{ width: "80%", margin: "50px auto" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "80px",
          }}
        >
          {Boxes.map((item) => {
            return (
              <Box
                sx={{
                  width: "320px",
                  padding: "20px 30px 30px 30px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  position: "relative",
                  marginTop: "50px",
                }}
              >
                <img
                  src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                  alt=".."
                  style={{
                    position: "absolute",
                    top: "-135px",
                    width: "45px",
                    zIndex: "-1000",
                    left: "130px",
                    transform: "rotate(270deg)",
                  }}
                />
                <img
                  style={{
                    width: "35px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={item.img}
                  alt="..."
                />
                <Typography
                  sx={{
                    fontSize: "15px",
                    textAlign: "center",
                    margin: "10px",
                    fontWeight: "bold",
                    color: "black",
                  }}
                  variant="p"
                  color="inherit"
                >
                  {item.one}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{
                    textAlign: "center",
                    color: "#757575",
                    fontSize: "13px",
                    lineHeight: "20px",
                  }}
                >
                  {item.two}
                </Typography>
                <Link
                  className="hover"
                  to={item.to}
                  onClick={() => {
                    if (window.scrollY > 600) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                  style={{
                    borderRadius: "20px",
                    padding: "5px",
                    transition: "1s",
                    margin: "20px auto 5px auto",
                    width: "85%",
                    bottom: "20px",
                    fontSize: "14px",
                    textAlign: "center",
                    textDecoration: "none",
                    color: "black",
                    marginTop: "20px",
                    backgroundColor: "#B7F22D",
                    fontWeight: "500",
                  }}
                >
                  {item.link}
                </Link>
              </Box>
            );
          })}
        </Box>
      </Box>

      <Box
        height="100vh"
        className="meet"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "50px 0px",
          position: "relative",
        }}
      >
        <img
          src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt=".."
          className="left"
          style={{ zIndex: "-100" }}
        />
        <img
          src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt="..."
          className="right"
          style={{ zIndex: "-100" }}
        />
        <Typography
          variant="h1"
          color="inhrit"
          sx={{
            fontSize: "30px",
            textAlign: "center",
            color: "black",
            letterSpacing: "1px",
            fontWeight: "500",
          }}
        >
          Meet Mobezero
        </Typography>
        <CardMedia
          sx={{ width: "50vw", margin: "auto" }}
          component="img"
          image="https://static.wixstatic.com/media/bdd318_1aef43ca917541d1b5b9861b16bdb1fd~mv2.png/v1/fill/w_791,h_608,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/Group%2039.png"
        />
      </Box>

      <Typography
        variant="h1"
        color="inherit"
        sx={{
          color: "black",
          textAlign: "center",
          fontSize: "30px",
          letterSpacing: "1px",
          marginBottom: "30px",
          marginTop: "30px",
          fontWeight: "500",
        }}
      >
        Words of Appreciation from Our Users
      </Typography>
      <Google data={data} />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xs: "90vw", md: "80vw" },
          backgroundColor: "white",
          borderRadius: "20px",
          padding: { xs: "25px", md: "50px" },
          margin: "350px auto",
          position: "relative",
        }}
      >
        <CardMedia
          sx={{
            width: { xs: "300px", sm: "600px" },
            height: { xs: "200px", sm: "300px" },
            borderRadius: "30px",
            position: "absolute",
            right: "70px",
            transform: "translateY(-80%)",
            zIndex: "-100",
          }}
          image="https://static.wixstatic.com/media/bdd318_e1383242ff1f4a6c859549f138c88a2e~mv2.jpg/v1/crop/x_0,y_722,w_5760,h_2395/fill/w_673,h_280,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/lovely-blonde-woman-walking-by-green-bushes-with-smile-carrying-smartphone-cup-coffee.jpg"
          component="img"
        />
        <CardMedia
          sx={{
            width: { xs: "30px", md: "50px" },
            display: { xs: "none", md: "block" },
            transform: "rotate(90deg)",
            position: "absolute",
            right: "180px",
            top: "-105px",
          }}
          component="img"
          image="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
        />
        <Typography
          variant="h1"
          color="inherit"
          sx={{
            fontSize: "30px",
            color: "black",
            textAlign: "left",
            letterSpacing: "1px",
            fontWeight: "500",
            marginBottom: "50px",
          }}
        >
          How Does it Work?
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            gap: "50px",
            justifyContent: { xs: "center", lg: "space-around" },
          }}
        >
          {work.map((item) => {
            return (
              <Grid
                item
                xs={10}
                md={4}
                lg={3}
                sx={{ position: "relative", paddingBottom: "60px" }}
              >
                <Typography
                  variant="h1"
                  color="inherit"
                  sx={{
                    fontSize: "20px",
                    fontWeight: "500",
                    color: "black",

                    textAlign: "left",
                    marginBottom: "30px",
                  }}
                >
                  {item.h1}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{ fontSize: "13.5px", lineHeight: "20px" }}
                >
                  {item.p}
                </Typography>
                <ol
                  color="inherit"
                  style={{ fontSize: "18px", lineHeight: "23px" }}
                >
                  {item.ol.map((item) => {
                    return (
                      <li style={{ marginTop: "10px", display: "block" }}>
                        {item}
                      </li>
                    );
                  })}
                </ol>
                <Link
                  style={{
                    color: "#EF3575",
                    display: "block",
                    marginTop: " 40px",
                    fontSize: "17px",
                    fontWeight: "600",
                    position: "absolute",
                    bottom: "0px",
                  }}
                  to={item.to}
                  onClick={() => {
                    if (window.scrollY > 600) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {item.link}
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};

export default Home;
