import { Box, Typography } from "@mui/material";
import React from "react";

const FAQ = () => {
  return (
    <Box
      sx={{
        margin: "220px auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <title>FAQ</title>

      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          textAlign: "center",
          fontWeight: "bold",
          letterSpacing: "2px",
        }}
      >
        FAQ
      </Typography>
      <hr
        style={{
          width: "45vw",
          margin: " 30px auto",
          border: "1.5px solid black",
        }}
      />
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "30px auto",
          fontWeight: "bold",
        }}
      >
        Travel eSIM
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        What is mobezero eSIM?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero eSIM is a virtual SIM card that allows you to use your mobile
        phone or device without a physical SIM card. It provides a simple and
        convenient way for travelers to stay connected while on the go.
      </Typography>

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        What are the benefits of using Mobezero eSIM?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero eSIM offers several benefits to travelers, including cost
        savings, greater flexibility, and the ability to use multiple phone
        numbers on a single device.
      </Typography>

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        How do I activate my Mobezero virtual SIM?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        To activate your mobezero Virtual SIM card, you will need to purchase a
        plan and download the virtual SIM onto your phone or device. mobezero
        offers detailed instructions and support to guide you through the
        activation process.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        Can I use mobezero eSIM on any phone or device?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero eSIM is compatible with a wide range of devices, including the
        latest smartphones and tablets from Apple, Samsung, Google, and other
        manufacturers. If you want to check the device compatibility, you can do
        the compatibility test
      </Typography>

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        Can I use mobezero eSIM in any country?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero eSIM is available in over 150 countries around the world, with
        coverage in most major cities and tourist destinations.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        How much does mobezero eSIM cost?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero offers a range of plans to suit different travel needs and
        budgets. You can see the plans by searching for your destination
      </Typography>

      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        What happens if I run out of data or credit on my mobezero eSIM?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero offers easy options to ensure that you always have data and
        credit when you need it most. You can purchase additional data or credit
        through the mobezero website.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        How do I use Mobezero travel eSIM?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        You can purchase a plan that suits your data needs on the Mobezero
        website, and download the eSIM profile onto your phone. Once activated,
        you can start using data in the country you're traveling to.
      </Typography>
      <Typography
        variant="h3"
        color="inherit"
        sx={{
          fontSize: "18px",

          textAlign: "left",
          width: { xs: "90vw", md: "70vw" },
          margin: "auto",
          fontWeight: "bold",
        }}
      >
        What is the duration of mobezero travel eSIM plans in the USA?
      </Typography>
      <Typography
        variant="p"
        color="inherit"
        sx={{
          width: { xs: "90vw", md: "70vw" },
          fontSize: "15px",
          margin: "30px auto",
          lineHeight: "25px",
        }}
      >
        Mobezero travel eSIM plans vary in duration, with options available for
        7 to 30 days.
      </Typography>
    </Box>
  );
};

export default FAQ;
