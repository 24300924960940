import { Box, Typography, CardMedia, Grid } from "@mui/material";
import React from "react";
import "../css/shares.css";
import "../css/style.css";
import Google from "../components/Google";
import Accor from "../components/Accor";
import num from "../images/num.webp";
const Numbers = () => {
  let acc = [
    {
      summary:
        "What is a virtual mobile number and how is it different from a regular phone number?",
      text: "A virtual mobile number operates independently of a physical SIM card or device, enabling call and message reception on mobile devices or via a virtual numbers app.",
    },
    {
      summary: "Can I use Mobezero virtual numbers app for my business?",
      text: "Absolutely! Mobezero virtual phone numbers are crafted to streamline your communication needs, serving as an ideal second phone number for your business.",
    },
    {
      summary: "What is the benefit of having a second phone number?",
      text: "Having a second phone number for business allows you to separate your personal and professional calls and messages, while still using the same device.",
    },
    {
      summary:
        "Does Mobezero virtual number service offer a second phone number option?",
      text: "Yes, Mobezero app offers virtual numbers that can be used as a second phone number",
    },
    {
      summary: "What are the benefits of using Mobezero virtual numbers?",
      text: "Mobezero virtual number app is one of the best app for virtual phone number, as it offers reliable call and message forwarding, advanced call management features, and global coverage.",
    },
    {
      summary:
        "Does Mobezero virtual number service offer a second phone number option?",
      text: "Yes, Mobezero app offers virtual numbers that can be used as a second phone number",
    },
    {
      summary: "What are the benefits of using Mobezero virtual numbers?",
      text: "TMobezero virtual number app is one of the best app for virtual phone number, as it offers reliable call and message forwarding, advanced call management features, and global coverage.",
    },
    {
      summary:
        "Can I receive SMS messages on a virtual phone number from Mobezero?",
      text: "Yes, Mobezero's virtual phone numbers support SMS messaging.",
    },
    {
      summary: "How to Activate Your Virtual Phone Number in Mobezero?",
      text: "​Download the app and Sign up in a few clicks, Select the phone number you want and buy for calling and receiving calls instantly.",
    },
    {
      summary: "How to buy Virtual Number with Mobezero?",
      text: `Follow these steps:

      1. Install & Sign Up: Get the Mobezero app & register in minutes.
      
      2. Choose Number: Pick your desired virtual phone number for calls & texts.
      
      3. Buy & Activate: Purchase & instantly activate your number with a few clicks.`,
    },
    {
      summary: "What is the cost of a virtual phone number?",
      text: "Mobezero's virtual numbers offer competitive pricing, with flexible plans to suit your business or personal needs.",
    },
    {
      summary: "Is Mobezero a virtual number app?",
      text: "Yes, Mobezero is a versatile communication app offering virtual second phone numbers, data eSIM for travel, and affordable international calling.",
    },
    {
      summary: "What kind of customer support does Mobezero offer?",
      text: "Mobezero offers 24/7 customer support to ensure that your virtual number is always functioning at its best. Our support team is available via phone, email, and live chat to assist you with any questions or issues.",
    },
    {
      summary:
        "Is there a contract or minimum commitment required for using Mobezero secondary phone numbers?",
      text: "No, there is no contract or minimum commitment required. You can use Mobezero secondary phone numbers on a pay-as-you-go basis, with no hidden fees or charges.",
    },
    {
      summary:
        "Can I Buy USA Virtual Phone Number with Mobezero Virtual Number App?",
      text: "Absolutely! Mobezero provides the convenience of purchasing a virtual phone number for the USA, UK, Canada and more countries. Easily establish a local presence, receive calls, and texts without the need for a physical SIM card.",
    },
  ];
  let data = [
    {
      imgOne:
        "https://static.wixstatic.com/media/b54bb7_0415034e41ed46acaa0e9a465e2eeaad~mv2.png/v1/fill/w_86,h_83,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/asset-1.png",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "Mobezero was recommended by a friend for my abroad trip. It offered a virtual number before I arrived and local calling bundles. Mobezero helped me to stay connected from the start of my trip. highly recommended.",
      h4: "Jennifer Lorens",
    },
    {
      imgOne:
        "https://static.wixstatic.com/media/b54bb7_0261398d0ddc4383a4464ebbee0e2707~mv2.png/v1/fill/w_86,h_83,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/nidhi-dorairaj-bruce.png",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "Probably the best app to get a virtual phone number. After trying few apps I got a USA number. The price was reasonable about $10 for whole year and call quality was pretty good. Highly recommended for anyone looking to get number from USA.",
      h4: "Justin JB",
    },
    {
      imgOne:
        "https://static.wixstatic.com/media/b54bb7_4a9b2f6095ee49f48281111911167bfc~mv2.png/v1/fill/w_86,h_83,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/istockphoto-1.png",
      imgTwo:
        "https://static.wixstatic.com/media/b54bb7_d65005bb6ad74c879aa20c8276696353~mv2.png/v1/fill/w_139,h_28,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-logo.png",
      imgThree:
        "https://static.wixstatic.com/media/b54bb7_4fdd82a2d546432e9a4ee76df10ee556~mv2.png/v1/fill/w_106,h_25,al_c,lg_1,q_85,enc_auto/rating%20(-8.png",
      p: "I used this to call Australia from Malaysia, and it was completely hassle free. Didn't have any downtime or issues with connectivity. It's a great app to use when you're travelling.",
      h4: "Maneka Dayaratne",
    },
  ];
  let Boxes = [
    {
      img: "https://static.wixstatic.com/media/b54bb7_13b63898f50a49388f3bf373217daecf~mv2.png/v1/fill/w_61,h_61,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/high-five.png",
      one: "User-Friendly Interface:",
      two: "Mobezero makes virtual number management easy with a user-friendly platform for configuring settings and preferences.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_07be7a3e39c148d5b59cabb77638e901~mv2.png/v1/fill/w_61,h_61,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/premium%20(1).png",
      one: "Premium Call Quality",
      two: "Mobezero guarantees that your calls will be clear and reliable, with our cutting-edge network and unique call-forwarding capabilities.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_5197564dce8e42058f7c753458c4fdaa~mv2.png/v1/fill/w_61,h_61,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/network.png",
      one: "Stay Connected Across the Globe:",
      two: "Stay Connected Across the Globe:Your Virtual Presence, Anywhere. Choose virtual numbers from UK, USA & more countries ",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_9a6b338029d744abbd00c64d459b0c9e~mv2.png/v1/fill/w_61,h_61,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/money.png",
      one: "Cost-Effective Pricing:",
      two: "Mobezero has customizable pricing plans that may be adapted to your unique needs ensuring you get the most value",
    },
  ];

  let BoxesTwo = [
    {
      img: "https://static.wixstatic.com/media/b54bb7_8f9c154d4aae4c08b7ed1d78257e1840~mv2.png/v1/fill/w_73,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/phone.png",
      one: "Easy Calling",
      two: "Mobezero virtual numbers allows you to make and receive calls exactly like any other phone number",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_f6bfb6ac1c214ef1938f1d77d80db9f0~mv2.png/v1/fill/w_73,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/best-price.png",
      one: "Affordable Pricing",
      two: "Mobezero offers affordable pricing plans that suit your communication needs",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_30e829f6f74d462da3ec1e05e23a2be7~mv2.png/v1/fill/w_73,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/project-management.png",
      one: "Online Account Management",
      two: "Manage your account, view call logs, and configure settings online, from anywhere in the world.",
    },
    {
      img: "https://static.wixstatic.com/media/b54bb7_eda0e3e7e00146e683b43809383a0f63~mv2.png/v1/fill/w_73,h_73,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/customer-service.png",
      one: "24/7 Support",
      two: "Our dedicated support team is available 24/7 to help you with any queries or technical issues.",
    },
  ];

  let images = [
    "https://static.wixstatic.com/media/b54bb7_31ab6cf1325c4449b4ee7349303be4bb~mv2.png/v1/fill/w_270,h_539,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202024-0bb1-22%20at%2012-portrait.png",
    "https://static.wixstatic.com/media/b54bb7_23dbaaa79e2849168f5ca0f479f83298~mv2.png/v1/fill/w_270,h_539,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202024-01-22%20at%2012-portrnnnnait.png",
    "https://static.wixstatic.com/media/b54bb7_bf59dfad0c144c04923807522dec1be1~mv2.png/v1/fill/w_270,h_539,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202024-01-22%20at%2012-portrabvbbit.png",
    "https://static.wixstatic.com/media/b54bb7_1a8fd27cebe0402e884e6e3b1850dc0f~mv2.png/v1/fill/w_270,h_539,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/WhatsApp%20Image%202024-01-22%20at%2012-portrait.png",
  ];

  return (
    <Box className="virtual-parent" sx={{ position: "relative" }}>
      <title>Virtual Numbers</title>

      <Box
        sx={{
          position: "relative",
          margin: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{ display: "flex", width: "93vw", justifyContent: "center" }}
          className="home"
        >
          {/* <CardMedia className="im" component="img" image={num} sx={{objectFit:"cover",width:"93vw", height:"auto",borderRadius:"0px 0px 50px 50px",position :"absolute" , zIndex:"-1"}}/> */}

          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt=".."
            className="left"
          />
          <img
            src="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_126,h_348,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
            alt="..."
            className="right"
          />
          <Box
            className="parent"
            sx={{
              marginTop: "90px",
              transform: { lg: "translateX(50%)" },
              width: { xs: "90%", md: "400px", lg: "600px" },
              zIndex: "1000",
            }}
            id="parent"
          >
            <Typography
              variant="h1"
              color="inherit"
              sx={{
                fontSize: { xs: "14px", sm: "23px" },
                textAlign: "center",
                width: "100%",
                backgroundColor: "#E300518C",
                padding: { xs: "10px", md: "40px" },
                borderRadius: "50px",
              }}
            >
              <Typography
                variant="span"
                color="inherit"
                sx={{ fontSize: { xs: "12px", sm: "30px" }, color: "#FFC8DB" }}
              >
                Mobezero Virtual Numbers
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ color: "white", fontSize: { xs: "12px", sm: "30px" } }}
              >
                The Ultimate Solution
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ color: "white", fontSize: { xs: "12px", sm: "30px" } }}
              >
                for seamless connectivity
              </Typography>
              <Typography
                variant="span"
                color="inherit"
                sx={{ color: "#B7F22D", fontSize: { xs: "12px", sm: "30px" } }}
              >
                Get Mobezero App Now
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "30px",
                  marginTop: "20px",
                }}
              >
                <CardMedia
                  sx={{ width: { xs: "70px", sm: "100px" } }}
                  component="img"
                  title=""
                  image="https://static.wixstatic.com/media/b54bb7_52ec8d71b279416db41b7fd5365963be~mv2.png/v1/fill/w_188,h_69,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/google-play-badge-128x128.png"
                />
                <CardMedia
                  sx={{ width: { xs: "70px", sm: "100px" } }}
                  component="img"
                  title=""
                  image="https://static.wixstatic.com/media/b54bb7_415752a7f2fb4ffab8e45220d88e878c~mv2.png/v1/fill/w_188,h_69,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/app-store.png"
                />
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="second" sx={{ width: "80%", margin: "auto" }}>
        <Typography
          variant="h1"
          color="inherit"
          sx={{
            fontSize: "30px",
            marginBottom: "100px",
            marginTop: "60px",
            textAlign: "center",
            letterSpacing:"1px",
            fontWeight: "500",
          }}
        >
          <Typography
            variant="span"
            color="inherit"
            sx={{ display: "block", marginBottom: "10px" }}
          >
            {" "}
            Discover the Benefits of Mobezero Virtual
          </Typography>{" "}
          Numbers
        </Typography>
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "30px",
          }}
        >
          {Boxes.map((item) => {
            return (
              <Grid
                item
                xs={10}
                md={4}
                sx={{
                  padding: "30px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  position: "relative",
                  marginTop: "20px",
                }}
              >
                <img
                  src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                  alt=".."
                  style={{
                    position: "absolute",
                    top: "-89px",
                    transform: "rotate(90deg)",
                    width: "35px",
                    marginTop: "15px",
                    left: "100px",
                  }}
                />
                <img
                  style={{
                    width: "45px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={item.img}
                  alt="..."
                />
                <Typography
                  sx={{ fontSize: "15px", textAlign: "center", margin: "10px" }}
                  variant="p"
                  color="inherit"
                >
                  {item.one}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{
                    textAlign: "center",
                    color: "#757575",
                    fontSize: "13px",
                    lineHeight:"20px"
                  }}
                >
                  {item.two}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Box
        sx={{
          position: "relative",
          textAlign: "center",
          backgroundColor: "white",
          width: { xs: "90vw", sm: "75vw" },
          padding: "90px 50px",
          margin: "350px auto 100px auto",
        }}
      >
        <CardMedia
          sx={{
            width: { xs: "200", sm: "500px" },
            position: "absolute",
            left: "45%",
            height: "250px",
            borderRadius: "30px 30px 0px 0px",
            transform: "translate(-50%,-120%)",
            zIndex: "-100",
          }}
          image="https://static.wixstatic.com/media/b54bb7_f324a08cd637489faccd19a09915ae96~mv2.png/v1/fill/w_673,h_280,fp_0.49_0.26,q_85,usm_0.66_1.00_0.01,enc_auto/stock-photo-carefree-girl-in-elegant-hair-sitting-on-the-ground-and-talking-on-phone-outdo.png"
          component="img"
        />
        <CardMedia
          sx={{
            width: "50px",
            transform: "rotate(90deg)",
            left: "40%",
            position: "absolute",
            top: "-105px",
          }}
          image="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          component="img"
        />
        <Typography
          variant="p"
          color="inherit"
          sx={{
            color: "#757575",
            display: "block",
            fontSize: "20px",
            marginTop: "5px",
          }}
        >
          Whether you're an individual keeping in touch with loved ones
          overseas,
        </Typography>
        <Typography
          variant="p"
          color="inherit"
          sx={{
            color: "#757575",
            display: "block",
            fontSize: "20px",
            marginTop: "5px",
          }}
        >
          or a frequent traveler seeking a reliable communication solution on
          the go.
        </Typography>
        <Typography
          variant="h2"
          color="inherit"
          sx={{ fontSize: "30px", fontWeight: "500", marginTop: "30px" }}
        >
          Mobezero Virtual Numbers
        </Typography>
        <Typography
          variant="h2"
          color="inherit"
          sx={{ fontSize: "30px", fontWeight: "500" }}
        >
          has you covered
        </Typography>
      </Box>

      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          marginBottom: "100px",
          marginTop: "60px",
          textAlign: "center",
          fontWeight: "500",
          letterSpacing:"1px"
        }}
      >
        Unleash the Power of Mobezero Virtual Numbers
      </Typography>
      <Box
        className="third"
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            gap: "50px",
            flexDirection: "row",
            width: "90vw",
            margin: "auto",
          }}
        >
          {BoxesTwo.map((item) => {
            return (
              <Grid
                item
                xs={10}
                md={4}
                sx={{
                  padding: "30px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "20px",
                  position: "relative",
                  marginTop: "50px",
                }}
              >
                <CardMedia
                  sx={{
                    position: "absolute",
                    top: "-89px",
                    transform: "rotate(90deg)",
                    width: "35px",
                    marginTop: "15px",
                    left: "100px",
                  }}
                  component="img"
                  title=""
                  image="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_54,h_281,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
                />
                <img
                  style={{
                    width: "45px",
                    display: "block",
                    margin: "20px auto",
                  }}
                  src={item.img}
                  alt="..."
                />
                <Typography
                  sx={{ fontSize: "15px", textAlign: "center", margin: "10px" }}
                  variant="p"
                  color="inherit"
                >
                  {item.one}
                </Typography>
                <Typography
                  variant="p"
                  color="inherit"
                  sx={{
                    textAlign: "center",
                    color: "#757575",
                    fontSize: "13.5px",
                    margin:"auto",
                    lineHeight:"22px",
                     width:"90%"
                  }}
                >
                  {item.two}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
      </Box>

      <Typography
        variant="h1"
        color="inherit"
        sx={{
          fontSize: "30px",
          marginBottom: "50px",
          marginTop: "60px",
          textAlign: "center",
          letterSpacing:"1px",
          fontWeight: "500",
        }}
      >
        How Does it Work?
      </Typography>
      <Box
        sx={{
          width: { xs: "83vw", md: "80vw" },


          backgroundColor: "white",
          borderRadius: "20px",
          margin: "auto",
          padding: { xs: "10px", sm: "50px" },
          position: "relative",
        }}
      >
        <img
          style={{
            position: "absolute",
            top: "-103px",
            right: "180px",
            transform: "rotate(90deg)",
          }}
          src="https://static.wixstatic.com/media/b54bb7_2e4d4ebe4c4e471989ae879e31e0484c~mv2.png/v1/fill/w_48,h_253,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
          alt="..."
        />

        <Box
          className="images"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "50px",
            marginTop: "30px",
            flexWrap: "wrap",
          }}
        >
          {images.map((item, index) => {
            return (
              <Box>
                <img style={{ width: "200px" }} src={item} alt="..." />
                <Typography
                  variant="h4"
                  color="inherit"
                  sx={{
                    textAlign: "center",
                    fontSize: "18px",
                    fontWeight: "500",
                  }}
                >
                  step {index + 1}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Box>
      <Google data={data} />

      <Accor acc={acc} />
    </Box>
  );
};

export default Numbers;
