import { Box, CardMedia } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "../css/shares.css";
import "../css/style.css";
import { Link } from "react-router-dom";
import Pln from "./pln";

const Footer = () => {
  return (
    <Box
      className="footer"
      sx={{
        position: "relative",
        marginTop: "100px",
        backgroundColor: "#293E5A",
        flexDirection: { xs: "column", sm: "row" },
        gap: { xs: "30px" },
        display: "flex",
        paddingBottom: "120px",
        justifyContent: "space-around",
      }}
    >
      <Box
        className="arrow"
        sx={{
          display: "flex",
          fontFamily: "poppins-extralight,poppins,sans-serif",
          borderRadius: "0px 13px 13px 0px",
          minWidth: "300px",
          alignItems: "center",
          top: "0px",
          left: "50%",
          transform: "translate(-50%,-50%)",
          backgroundColor: "white",
          position: "absolute",
        }}
      ></Box>

      <Pln />

      <CardMedia
        component="img"
        sx={{
          position: "absolute",
          top: "50%",
          transform: "translateY(-70%)",
          left: "-20px",
          height: "150px",
          width: "55px",
          borderRadius: "0px 20px 20px 0px",
        }}
        image="https://static.wixstatic.com/media/b54bb7_3ac13f1738994748bfb18cc7df9a8676~mv2.png/v1/fill/w_64,h_239,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Group%201.png"
      />
      <Box>
        <Box className="icons">
          <Link to="https://www.facebook.com/Correctsoc">
            <FacebookIcon />
          </Link>

          <Link>
            <TwitterIcon />
          </Link>

          <Link>
            <YouTubeIcon />
          </Link>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Link
          to="/privacy"
          onClick={() => {
            if (window.scrollY > 600) {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        >
          Privacy Policy
        </Link>
        <Link
          to="security"
          onClick={() => {
            if (window.scrollY > 600) {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        >
          Security Policy
        </Link>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Link
          to="FAQ"
          onClick={() => {
            if (window.scrollY > 600) {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }
          }}
        >
          FAQ
        </Link>
      </Box>
      <Box>
        <img
          src="https://static.wixstatic.com/media/b54bb7_7c5736bc8cbc4e33a25cb90be937b598~mv2.png/v1/fill/w_93,h_93,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Untitled%201.png"
          alt="..."
        />
      </Box>
      <KeyboardArrowUpIcon
        sx={{
          color: "white",
          position: "absolute",
          top: "30px",
          right: "50px",
          cursor: "pointer",
        }}
        onClick={() => {
          if (window.scrollY > 600) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }
        }}
      />
    </Box>
  );
};

export default Footer;
