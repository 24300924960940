import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Close } from "@mui/icons-material";
import { AppBar, Box, Link, CardMedia } from "@mui/material";
import "../css/shares.css";
import "../css/style.css";
import { NavLink } from "react-router-dom";
import logo from "../images/logo.png";

const Header = () => {
  return (
    <Box>
      <AppBar
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          backgroundColor: "white",
          padding: "10px 30px",
        }}
      >
        <Box>
          <CardMedia
            component="img"
            image={logo}
            sx={{
              margin: "10px 0px",
              objectFit: "cover",
              objectPosition: "-60% 45%",
            }}
          />
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "50px",
          }}
        >
          <Box
            className="links"
            id="links"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "center",
            }}
          >
            <Box id="nav" sx={{ display: { sm: "block", md: "none" } }}>
              <Close
                style={{
                  color: "black",
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
                onClick={() => {
                  document.getElementById("links").style.right = "-100%";
                }}
              />
            </Box>
            <div
              className="nav"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <NavLink
                to="/"
                onClick={() => {
                  document.getElementById("links").style.right = "-100%";

                  if (window.scrollY > 600) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                Home
              </NavLink>
              <NavLink
                to="eSIM"
                onClick={() => {
                  document.getElementById("links").style.right = "-100%";

                  if (window.scrollY > 600) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                eSIM
              </NavLink>
              <NavLink
                to="virtual-numburs"
                onClick={() => {
                  document.getElementById("links").style.right = "-100%";

                  if (window.scrollY > 600) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                Virtual Numbers
              </NavLink>
              <NavLink
                to="calling"
                onClick={() => {
                  document.getElementById("links").style.right = "-100%";

                  if (window.scrollY > 600) {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }
                }}
              >
                International Calling
              </NavLink>
            </div>
          </Box>
        </Box>

        <Box>
          <NavLink
            className="contact-link"
            to="contact"
            style={{
              backgroundImage: "linear-gradient(#FF806D,#F43886)",
              color: "white",
              borderRadius: "20px",
              display: "inline-block",
              padding: "10px 20px",
            }}
            onClick={() => {
              if (window.scrollY > 600) {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }
            }}
          >
            Contact Us
          </NavLink>
        </Box>

        <Box sx={{ marginRight: "25px", display: { sm: "block", md: "none" } }}>
          <MenuIcon
            sx={{ color: "#111", cursor: "pointer", fontSize: "30px" }}
            onClick={() => {
              document.getElementById("links").style.right = "0";
            }}
          />
        </Box>
      </AppBar>
    </Box>
  );
};

export default Header;
