import { Box, Typography } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Box
      className="error"
      sx={{
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Typography
        variant="h1"
        color="inherit"
        sx={{ fontSize: "50px", fontWeight: "bold", marginTop: "110px" }}
      >
        404
      </Typography>
      <Typography
        variant="h2"
        color="inherit"
        sx={{ fontSize: "45px", margin: "10px", fontWeight: "bold" }}
      >
        PAGE NOT FOUND
      </Typography>
    </Box>
  );
};

export default NotFound;
